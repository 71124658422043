#inicio {
  position: relative;
  background-image: url("../../../public/index/winter_I_love_you.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 3.5rem);

  .eri {
    text-transform: uppercase;
    position: absolute;
    top: 25vh;
    left: 1rem;
    font-size: 1rem;
    text-shadow: 1px 1px 1px $detalle6;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
      color: white;
      text-shadow: 4px 4px 8px #888888;
    }
  }
  .paz {
    position: absolute;
    top: 58vh;
    left: 2rem;
    font-size: 2rem;
    text-shadow: 1px 1px 1px $detalle6;

    &:hover {
      opacity: 0.7;
      color: white;
      text-shadow: 4px 4px 8px #888888;
    }
  }
  @include desktop {
    background-position: center;
    height: 100vh;
    .paz {
      position: absolute;
      top: 40vh;
      left: 15rem;
      font-size: 2rem;
      text-shadow: 1px 1px 1px $detalle6;
      &:hover {
        opacity: 0.7;
        color: white;
        text-shadow: 4px 4px 8px #888888;
      }
    }
  }
  .flecha {
    position: absolute;
    bottom: 0rem;
    left: 49%;
    font-size: 2rem;
    text-shadow: 1px 1px 1px $detalle6;
    z-index: 2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    background-color: $detalle4;
    z-index: 1;
  }
}
.sinBorde {
  --bs-gutter-x: 0rem;
}
h2 {
  margin-left: 10%;
  font-weight: bold;
  line-height: 4rem;
  font-size: 2rem;
}
#bio {
  position: relative;
  background-color: $azul;
  .cajaBio {
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
    @include desktop {
      padding-left: 4rem;
      padding-right: 4rem;
      text-align: start;
    }
  }
  & a {
    color: $blanco;
    font-size: 2.5rem;
    margin: 3rem;
    &:hover {
      color: $gris;
    }
  }
  .imgRedonda {
    width: 10rem;
    height: 10rem;
    border-radius: 10rem;
    border: 0.3rem solid $azul;
    background-color: $blanco;
    &.floatLeft {
      // margin-left: 5%;
      margin-top: 1%;
      margin-bottom: 1%;
      margin-right: 3%;
    }
  }
  .imgRedonda.floatRigth {
    margin-right: 10%;
    float: right;
  }

  & img {
    border: 0.15rem solid $blanco;
    margin-bottom: 1.5rem;
  }
  @include desktop {
    .imgRedonda.floatLeft {
      float: left;
      margin-bottom: 0;
    }
  }
  p {
    // padding: 2rem;
    font-size: 1.4rem;
  }
  .flecha {
    position: absolute;
    bottom: 0rem;
    left: 49%;
    font-size: 2rem;
    text-shadow: 1px 1px 1px $detalle6;
    z-index: 2;
  }
}
