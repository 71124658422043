#proyectos {
  background-color: #e5e9ee;
  color: #132f57;
  position: relative;
  height: fit-content;
  h2 {
    color: $azul;
    background-color: $letra;
  }

  .flecha {
    position: absolute;
    bottom: 0rem;
    left: 49%;
    font-size: 2rem;
    text-shadow: 1px 1px 1px $detalle6;
    z-index: 2;
    color: $azul;
  }
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 5rem;
  }
  .imgDesktop {
    width: 100%;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5), 0 4px 6px rgba(0, 0, 0, 0.08);
    @include desktop {
      padding-left: 3rem;
      padding-right: 3rem;
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
  }
  .slick-track {
    display: flex !important;
    align-items: center;
    justify-content: center;
    img {
      aspect-ratio: 4/3;
      width: 100%;
      object-fit: cover;
    }
  }
  .cajaCar {
    position: relative;
  }
  .pieContacto {
    background-color: rgba(19, 47, 87, 0.8);
    display: block;
    position: absolute;
    width: 100%;
    bottom: 0;
    align-items: center;
    text-align: center;
    a {
      font-size: 1.2rem;
      font-style: normal;
      font-variant: normal;
      font-weight: 400;
      line-height: 30px;
      text-decoration: unset;
      color: $blanco;
      margin: auto;
      width: 100%;
      text-align: center;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
figure {
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
}
figure .textoSobre {
  color: $blanco;
  text-align: start;
  background: $azul;
  transition: opacity 0.4s linear;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0.8rem;
  bottom: auto;
  left: 0.8rem;
  opacity: 0;
  padding: 1rem;
  font-family: "Courier New", Courier, monospace;
}
figure:hover .textoSobre {
  opacity: 0.75;
}
.cuevaza {
  margin: 0;
  padding: 0;
  .figure {
    margin-bottom: 0;
  }
  .paradas {
    img {
      aspect-ratio: 3/4;
      width: 100%;
      object-fit: cover;
    }
  }
  .cuevas {
    padding: 0;
    margin: 0;
    @include desktop {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }
}
