#statement {
  width: auto;
  background-color: $blanco;
  text-decoration-color: $azul;
  & p {
    width: auto;
    background-color: $blanco;
    color: $azul;
    font-weight: bold;
    font-size: 1.5rem;
    padding-top: 1rem;
    @include desktop {
      padding: 2rem;
    }
  }
  & h2 {
    width: auto;
    background-color: $blanco;
    color: $azul;
  }
  & a {
    font-size: 2.5rem;
  }
  & img {
    width: 90%;
    border-radius: 2rem;

    transition: all 2s;
    &:hover {
      width: 100%;
    }
    @include desktop {
      & {
        width: 60%;
        border-radius: 2rem;
      }
    }
  }

  position: relative;

  .flecha {
    position: absolute;
    bottom: 0rem;
    left: 49%;
    font-size: 2rem;
    text-shadow: 1px 1px 1px $detalle6;
    z-index: 2;
    color: $azul;
  }
}
