@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;1,100&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
@font-face {
  font-family: "Paz";
  src: url("../../../public/fuente/Paz-Regular\ \(1\).ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

// variables
$azul: #132f57;
$blanco: #e5e9ee;
$gris: #7e8288;
$fondo: #132f57;
$letra: #e5e9ee;
$detalle: rgba(19, 47, 87, 1);
$detalle8: rgba(19, 47, 87, 0.8);
$detalle6: rgba(19, 47, 87, 0.6);
$detalle4: rgba(19, 47, 87, 0.4);
$detalle2: rgba(19, 47, 87, 0.2);

// mixin
@mixin desktop {
  @media screen and (min-width: 768px) {
    @content;
  }
}
@mixin mobile {
  @media screen and (max-width: 767.9px) {
    @content;
  }
}

* {
  margin: 0;
  padding: 0;
  color: $letra;
  font-family: "Paz";
  letter-spacing: 2px;
}

.negrita {
  font-weight: bold;
}
.boton {
  width: fit-content;
  background-color: $detalle;
  color: $letra;
  text-transform: uppercase;
  font-size: 0.8rem;
  text-decoration: none;
  padding: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 1rem;
  cursor: pointer;
  box-shadow: 2px 2px 4px #888888;
  transition-duration: 0.4s;
  &:hover {
    opacity: 0.7;
    color: white;
    box-shadow: 4px 4px 8px #888888;
  }
  i {
    color: $letra;
    margin: 0.5rem;
  }
}

.contenido {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0);
  padding-bottom: 5rem;
  i {
    position: absolute;
    font-size: 2rem;
    color: $letra;
  }
}

.spinner {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 5px solid $letra;
  background: transparent;
  border-top-color: $detalle6;
  border-bottom-color: $detalle6;
  border-right-color: $detalle6;
  border-left-color: $letra;
  animation: spin 4s linear infinite;
  position: relative;
}

@keyframes spin {
  0% {
    background: transparent;
    transform: rotate(720deg);
  }
}

.cajaUpload {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
}
.cuadro {
  border-bottom: 2px solid grey;
}
.btnCant {
  border: none;
  font-size: 0.8rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  transition-duration: 0.4s;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    box-shadow: 4px 4px 8px #888888;
  }
}
.gris {
  color: rgba(128, 128, 128, 0.7);
  font-size: 0.8rem;
}
.inputStock {
  width: 2rem;
  text-align: center;
}

.input-group {
  justify-content: flex-end;
}
.descripcion {
  p {
    margin-bottom: 0;
  }
}

// .modal{
//   z-index: 10000;

// }

.modal-content {
  margin-top: 4rem;
  box-shadow: 4px 4px 8px #888888;
}
.modal-title {
  color: $fondo;
  font-weight: bold;
  text-transform: uppercase;
}
.modal-body {
  p {
    color: $fondo;
    font-weight: 600;
  }
}

#cv {
  border-top: solid 1px $letra;
  background-color: $blanco;
  font-family: "Josefin";
  padding-bottom: 0;
  & h1 {
    background-color: $azul;
    color: $blanco;
    font-family: "Josefin";
    padding: 1%;
    margin-bottom: 2%;
    font-size: 2rem;
  }
  & h3 {
    color: $azul;
    font-family: "Josefin";
    padding-left: 5%;
    font-size: 1.3rem;
    width: 100%;
    padding-bottom: 1rem;
    &:hover {
      color: $gris;
      opacity: 0.9;
    }
    i {
      color: $azul;
    }
  }
  & hr {
    color: $azul;
  }
  & p {
    background-color: $blanco;
    color: $azul;
    font-family: "Josefin";
    font-size: 1rem;
    font-weight: 400;
    padding-left: 2rem;
    @include desktop {
      & {
        padding-left: 5rem;
        font-size: 1rem;
      }
    }
  }
  & .link {
    background-color: $blanco;
    color: $azul;
    font-family: "Josefin";
    font-size: 1rem;
    font-weight: 400;
    // padding-left: 2rem;
    @include desktop {
      & {
        // padding-left: 5rem;
        font-size: 1rem;
      }
    }
  }
  & a {
    text-decoration: none;
    color: $blanco;
    font-family: "Josefin";
    text-align: left;
    font-size: 1rem;
    &:hover {
      color: #7e8288;
    }
    @include desktop {
      & {
        font-size: 1.2rem;
      }
    }
  }
  & .p {
    color: $blanco;
    font-family: "Josefin";
    text-align: left;
    font-size: 1rem;
    &:hover {
      color: #7e8288;
    }
    @include desktop {
      & {
        font-size: 1.2rem;
      }
    }
  }
  .imgRedonda {
    margin-left: 5%;
    margin-bottom: 2rem;
  }
  .fondoAzulado {
    position: fixed;
    bottom: 0;
    display: flex;
    background-color: $azul;
    width: 100%;
    height: 2.5rem;
    justify-content: space-evenly;
    align-items: flex-end;
    flex-wrap: wrap;
    @include desktop {
      align-items: center;
    }
  }
  i {
    color: $blanco;
    font-family: "Josefin";
    font-size: 1.7rem;
    line-height: normal;
    &:hover {
      color: #7e8288;
    }
    @include desktop {
      & {
        font-size: 1.2rem;
      }
    }
  }
  .imgRedonda.floatRigth {
    float: none;
    @include desktop {
      float: right;
      margin-right: 10%;
    }
  }
}
/* PARQUES NACIONALES */
#parquesNacionales {
  padding-top: 0;
  padding-bottom: 0;
  width: auto;
  background-color: $blanco;
  text-decoration-color: $azul;
  @include desktop {
    & {
      padding-top: 0;
    }
  }
  & h2 {
    width: auto;
    background-color: $blanco;
    color: $azul;
  }
  & a {
    font-size: 0.8rem;
    color: $blanco;
    padding: 0.8rem;
    font-family: "Josefin";
  }
  & .centrarImagenes {
    justify-self: center;
  }
  & figcaption {
    font-size: 1.5rem;
    color: $azul;
    font-weight: bold;
  }
  & img {
    width: 100%;
  }
  .figure {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }
}
/* COLOUR ME IN */
#colourMeIn {
  padding-top: 0;
  padding-bottom: 0;
  width: auto;
  background-color: $blanco;
  text-decoration-color: $azul;
  @include desktop {
    & {
      padding-top: 0;
    }
  }
  & p {
    width: auto;
    background-color: $blanco;
    color: $azul;
    font-weight: bold;
    font-size: 1.7rem;
    padding-bottom: 1%;
  }
  & h2 {
    width: auto;
    background-color: $blanco;
    color: $azul;
  }
  & div {
    margin: 0;
    padding: 0;
  }
}
/* SUELOS DEL SUR */
#suelosDelSur {
  padding-top: 0;
  padding-bottom: 0;
  width: auto;
  background-color: $blanco;
  text-decoration-color: $azul;
  @include desktop {
    & {
      padding-top: 0;
    }
  }
  & p {
    width: auto;
    background-color: $blanco;
    color: $azul;
    font-weight: bold;
    font-size: 1.7rem;
    padding-bottom: 1%;
    padding-top: 1%;
    padding-left: 2rem;
    padding-right: 2rem;
    @include desktop {
      & {
        padding-top: 3%;
        padding-left: 0%;
        padding-right: 0%;
      }
    }
  }
  & .p {
    color: $azul;
  }
  & h2 {
    width: auto;
    background-color: $blanco;
    color: $azul;
  }
  & img {
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 20%;
    @include desktop {
      & {
        padding-right: 3%;
        padding-left: 3%;
      }
    }
  }
  & iframe {
    padding-top: 1%;
    padding-bottom: 1%;
    @include desktop {
      & {
        padding-right: 2.1%;
        padding: 2.1%;
      }
    }
  }
  @include desktop {
    & .cajaFigure {
      padding-right: 3%;
      padding-left: 3%;
      align-self: center;
    }
    & .imgDefor {
      @extend .cajaFigure;
      width: 100%;
    }
  }
}
/* LOS PAISAJES DEL FRIO */
#losPaisajesDelFrio {
  padding-top: 0;
  padding-bottom: 0;
  width: auto;
  background-color: $azul;
  text-decoration-color: $blanco;
  @include desktop {
    & {
      padding-top: 0;
    }
  }
  & p {
    color: $azul;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 0;
    width: 100%;
    & .letraComun {
      font-family: "Serafin";
      font-size: 0.8rem;
      color: $gris;
      font-weight: 300;
    }
  }
  & .cajaImg {
    background-color: $blanco;
    color: $azul;
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    margin-bottom: 1rem;
    transition: all 2s;
    & img {
      padding: 3%;
      border-radius: 1rem;
    }
    &:hover {
      width: 100%;
    }
    @include desktop {
      & {
        width: 50%;
        &:hover {
          width: 70%;
        }
      }
    }
  }
  & h2 {
    width: auto;
    background-color: $azul;
    color: $blanco;
  }
  @include desktop {
    & .row {
      margin-bottom: 1rem;
    }
  }
}
// FORAGING FOR PIGMENTS
#foragingForPigments {
  padding-top: 0;
  padding-bottom: 0;
  width: auto;
  height: 100%;
  background-color: $blanco;
  color: $azul;
  @include desktop {
    & {
      padding-top: 0;
    }
  }
  h2,
  p {
    color: $azul;
  }
  .cajaDeCajas {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    margin-bottom: 8rem;
    margin-right: 5rem;
    @media only screen and (min-width: 350px) {
      margin-bottom: 10rem;
    }
    @media only screen and (min-width: 522px) {
      margin-bottom: 14rem;
    }
  }
  .cajaImagen {
    width: 10rem;
    height: 8rem;
    margin-right: -5rem;
    margin-bottom: -3rem;
    transition: all 1s;
    opacity: 0.3;
    z-index: 0;
    @media only screen and (min-width: 350px) {
      width: 12.5rem;
      height: 10rem;
    }
    @media only screen and (min-width: 450px) {
      width: 14rem;
      height: 10rem;
    }
    @media only screen and (min-width: 522px) {
      width: 18rem;
      height: 14rem;
    }
    &:hover {
      opacity: 1;
      z-index: 1;
    }
    img {
      max-width: 100%;
      height: auto;
      box-shadow: $box-shadow;
    }
  }
}
footer {
  z-index: 100;
}
.indice {
  z-index: 2;
}
// INVERNAL
#invernal {
  padding-top: 0;
  padding-bottom: 0;
  width: auto;
  background-color: $blanco;
  color: $azul;
  @include desktop {
    & {
      padding-top: 0;
    }
  }

  h2,
  p {
    color: $azul;
  }
  img {
    object-fit: cover;
  }
  p {
    font-weight: bold;
  }
}
#lagoPuelo {
  padding-top: 0;
  padding-bottom: 0;
  width: auto;
  background-color: $blanco;
  color: $azul;
  @include desktop {
    & {
      padding-top: 0;
    }
  }
  h2,
  p {
    color: $azul;
  }

  p {
    font-weight: bold;
  }
}
// BLUE LOVE
.padProyectos {
  padding-top: 4rem;
  padding-bottom: 2.6rem;
}
#blueLove {
  width: auto;
  height: 100%;
  background-color: $azul;
  text-decoration-color: $blanco;
  @include desktop {
    & {
      padding-top: 0;
    }
  }
  & p {
    color: $blanco;
    font-size: 1.5rem;
  }
  img {
    border-radius: 2rem;
    padding: 0.5rem;
    @include desktop {
      padding: 1rem;
    }
  }
}
// HORNEAR EN POZO
#hornearEnPozo {
  padding-top: 0;
  padding-bottom: 0;
  width: auto;
  background-color: $blanco;
  color: $azul;
  @include desktop {
    & {
      padding-top: 0;
    }
  }
  h2,
  p {
    color: $azul;
  }
  img {
    object-fit: cover;
    padding: 0.5rem;
    @include desktop {
      padding: 1rem;
    }
  }
  .sombra {
    box-shadow: 0 0 0 10px black;
  }
}
/* REVELADOS ORGANICOS */
#reveladosOrganicos {
  padding-top: 0;
  padding-bottom: 0 * 1.5;
  width: auto;
  background-color: $blanco;
  text-decoration-color: $azul;
  @include desktop {
    & {
      padding-top: 0;
    }
  }
  & h2 {
    width: auto;
    background-color: $blanco;
    color: $azul;
  }
  & p {
    color: $azul;
    font-weight: bold;
    & a {
      color: $azul;
      font-weight: bold;
      font-family: "Paz";
      font-size: 1.5rem;
      margin: 0;
      padding: 0;
      &:hover {
        color: $gris;
      }
    }
  }
  & img {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  & figure {
    position: relative;
    overflow: visible;
    display: block;
    padding: 0;
    margin: 0;
  }

  & figure .revelados {
    color: $blanco;
    font-size: 1rem;
    padding: 1rem;
    padding-top: 3rem;
    font-family: "Josefin";
    text-align: end;
    background: #011610;
    transition: opacity 0.4s linear;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    right: 0.8rem;
    bottom: 0;
    left: 0;
    opacity: 0.6;
    border-left: 1.7rem dashed grey;
    border-right: 1.7rem dashed grey;
    &:hover {
      opacity: 0;
    }
  }
  & .cajaCelu {
    border: solid 0.2rem $azul;
    border-radius: 2rem;
    width: auto;
    @include desktop {
      border: solid 0.3rem $azul;
    }
    & img {
      border-radius: 2rem;
    }
    &:hover {
      opacity: 0.5;
    }
  }
  & .centrar {
    padding-left: 50%;
  }
}

// LOS LUGARES DONDE TE AME
#losLugaresDondeTeAme {
  padding-top: 0;
  padding-bottom: 0;
  width: auto;
  min-height: 96vh;
  height: 100%;
  background-color: $azul;
  text-decoration-color: $blanco;

  @include desktop {
    & {
      padding-top: 0;
    }
  }
  .imgDesktop {
    padding: 0;
    margin: 0;
    // box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5), 0 4px 6px rgba(0, 0, 0, 0.08);
    @include desktop {
      margin-left: 50%;
      transform: translate(-50%);

      padding-left: 3rem;
      padding-right: 3rem;
      padding-bottom: 1rem;
      padding-top: 1rem;
      width: 50%;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 1.5rem;
    // line-height: 1;
    opacity: 1;
    color: $letra !important;
    // background-color: red;
    // border-radius: 50%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  // .slick-prev:before,
  // .slick-next::before {
  //   content: "";
  // }

  // /* Agregar tu propio contenido personalizado y cambiar el color */
  // .slick-next::after {
  //   content: ">"; /* Símbolo ">" personalizado */
  //   color: $letra; /* Cambia el color a tu preferencia */
  //   font-size: 24px; /* Cambia el tamaño de fuente a tu preferencia */
  //   /* Agrega otros estilos según sea necesario */
  // }
  // .slick-prev::after {
  //   content: "<"; /* Símbolo ">" personalizado */
  //   color: $letra; /* Cambia el color a tu preferencia */
  //   font-size: 24px; /* Cambia el tamaño de fuente a tu preferencia */
  //   /* Agrega otros estilos según sea necesario */
  // }
  .slick-next {
    right: -3rem;
  }
  .slick-prev {
    left: -3.5rem;
  }
  .slick-track {
    display: flex !important;
    align-items: center;
    justify-content: center;
    img {
      // aspect-ratio: 4/3;
      width: 100%;
      // object-fit: ;
    }
  }

  & p {
    color: $blanco;
    font-size: 1.5rem;
  }

  .carousel-inner {
    text-align: center;
    vertical-align: middle;
    height: auto;
  }
  .carousel .item > img {
    display: inline-block;
    height: 100%;
  }
}
.azul {
  background-color: $azul;
}
// UN REFLEJO AZUL DE INVIERNO
#unReflejoAzulDeInvierno {
  width: auto;
  min-height: calc(100vh - 6.6rem);
  background-color: $azul;
  .largo {
    width: 53%;
    height: "auto";
    @include desktop {
      width: 53%;
      height: auto;
    }
  }
  .ancho {
    width: 47%;
    height: auto;
    @include desktop {
      width: 47%;
      height: auto;
    }
  }
  .largo2 {
    width: 100vw;
    height: "auto";
    @include desktop {
      width: 51.5%;
      height: auto;
    }
  }
  .ancho2 {
    width: 100vw;
    height: auto;
    @include desktop {
      width: 48.5%;
      height: auto;
    }
  }

  .imgDesktop {
    padding: 0;
    margin: 0;
    // box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5), 0 4px 6px rgba(0, 0, 0, 0.08);
    @include desktop {
      // padding-left: 3rem;
      // padding-right: 3rem;
      // padding-bottom: 1rem;
      // padding-top: 1rem;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 1.5rem;
    // line-height: 1;
    opacity: 1;
    color: $letra !important;
    // background-color: red;
    // border-radius: 50%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  // .slick-prev:before,
  // .slick-next::before {
  //   content: "";
  // }

  // /* Agregar tu propio contenido personalizado y cambiar el color */
  // .slick-next::after {
  //   content: ">"; /* Símbolo ">" personalizado */
  //   color: $letra; /* Cambia el color a tu preferencia */
  //   font-size: 24px; /* Cambia el tamaño de fuente a tu preferencia */
  //   /* Agrega otros estilos según sea necesario */
  // }
  // .slick-prev::after {
  //   content: "<"; /* Símbolo ">" personalizado */
  //   color: $letra; /* Cambia el color a tu preferencia */
  //   font-size: 24px; /* Cambia el tamaño de fuente a tu preferencia */
  //   /* Agrega otros estilos según sea necesario */
  // }
  .slick-next {
    right: -3rem;
  }
  .slick-prev {
    left: -3.5rem;
  }
  .slick-track {
    display: flex !important;
    align-items: center;
    justify-content: center;
    img {
      // aspect-ratio: 4/3;
      width: 100%;
      // object-fit: ;
    }
  }

  .row {
    --bs-gutter-x: 0;
  }
  & p {
    color: $blanco;
    font-size: 1rem;
    font-family: "Josefin";
    padding-left: 1rem;
    padding-right: 1rem;
    span {
      color: $blanco;
      font-size: 1rem;
      font-family: "Josefin";
      font-style: italic;
    }
    @include desktop {
      padding-right: 2rem;
    }
  }
  // h2 {
  //   line-height: normal;
  // }
  .carousel-inner {
    text-align: center;
    vertical-align: middle;
    height: auto;
    box-shadow: none !important;
  }
  .carousel .item > img {
    display: inline-block;
    height: 100%;
    box-shadow: none !important;
  }
  .almacen {
    position: relative;
    img {
      position: relative;
    }
    i {
      position: absolute;
      color: $blanco;
      background-color: rgba(19, 47, 87, 0.6);
      font-size: 1em;
      width: 2rem;
      border-radius: 50%;
      text-align: center;
      justify-content: center;
      padding-top: 0.2rem;
      cursor: pointer;
      &:hover {
        background-color: $azul;
        transform: scale(1.2);
      }
    }
    .logoAlmacen {
      width: 10%;
      position: absolute;
      top: 5%;
      left: 70%;
    }
    .pileta {
      top: 71%;
      left: 75%;
    }
    .mandarina {
      top: 54%;
      left: 69%;
    }
    .fuente {
      top: 71%;
      left: 5%;
    }
    .living {
      top: 48%;
      left: 22%;
    }
    .arriba {
      top: 22%;
      left: 52%;
    }
  }
  .carousel-control-prev {
    left: -8rem;
  }
  .carousel {
    vertical-align: top;
  }
  .carousel-control-next {
    right: -8rem;
  }
  .carousel-item {
    img {
      max-height: 415px;
      vertical-align: top;
    }
  }
  .modal-body {
    background-color: rgba(255, 255, 255, 0);
    vertical-align: top;
    height: 500px;
    box-shadow: none !important;
  }
  .modal-content {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    box-shadow: none !important;
  }
  .modal {
    background-color: rgba(128, 128, 128, 0.9);
    border: none;
    box-shadow: none !important;
  }
  .btn-close {
    background: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2rem;
    padding: auto;
    margin: auto;
    width: 100%;
    color: white;
    margin-bottom: 2rem;
  }
}
.azul {
  background-color: $azul;
}
.serSilvestre {
  color: $azul;
  @include desktop {
    margin-bottom: 3rem;
  }
}
#fliyer {
  border: none;
  #btnFliyer {
    position: absolute;
    right: 2.3rem;
    top: 1.8rem;
    border: none;
    color: $azul;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    background-color: rgba(0, 0, 0, 0);
    margin: auto;
  }
  .catalogo {
    width: 100%;
    background-color: $azul;
    color: $blanco;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-size: 0.7rem;
    margin-left: 42%;
    padding: 0.5rem;
    border-radius: 1rem;
  }
  img {
    border-radius: 2rem;
  }
}
.fade-in {
  opacity: 0;
  transition: opacity 3s ease-in-out;
}

.showText {
  opacity: 1;
}
.imgRedonda {
  width: 10rem;
  height: 10rem;
  border-radius: 10rem;
  border: 0.3rem solid $azul;
  background-color: $blanco;
  &.floatLeft {
    margin-left: 5%;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-right: 3%;
  }
}
.imgRedonda.floatRigth {
  margin-right: 10%;
  float: right;
}

.custom-icon-class {
  font-size: 3rem !important;
  color: $azul !important;
  border-radius: none !important;
}
.swal2-icon {
  border: none !important;
}
.swal2-html-container {
  font-size: 1.5rem !important;
  color: $azul !important;
  font-weight: bold !important;
  margin: 0 !important;
}
.swal2-styled.swal2-confirm {
  background-color: $azul !important;
  color: $letra !important;
  opacity: 1 !important;
}
.pCuevas {
  @include desktop {
    padding-left: 0.51rem;
    padding-right: 0.51rem;
  }
}
.cajaImg2 {
  background-color: $blanco;
  color: $azul;
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  margin-bottom: 1rem;
  transition: all 2s;
  & img {
    padding: 3%;
    border-radius: 1rem;
  }
  &:hover {
    width: 100%;
  }
  @include desktop {
    & {
      width: 70%;
      &:hover {
        width: 90%;
      }
    }
  }
}
.iframeJacinto {
  width: 20%;
  aspect-ratio: 9 / 16;
}
.rioImagenes {
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;
}
.numero {
  font-size: 0.8rem;
  color: #132f57;
}
