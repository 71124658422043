nav {
  position: fixed;
  z-index: 10000;
  top: 0;
  height: 4rem;
  width: 100vw;
  .logo {
    position: absolute;
    // top: 1.46rem;
    left: 15rem;
  }
  @include desktop {
    height: 4rem;
  }
  .navbarContainer {
    display: flex;
    width: 100%;
    height: 4rem;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    padding-bottom: 0;
    top: 0;
    gap: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
    @include desktop {
      height: 4rem;
      justify-content: flex-end;
      padding-right: 3rem;
    }
    img {
      max-height: 4rem;
    }
    a {
      text-decoration: none;
      font-weight: 400;
      font-size: 1.5rem;
      &:hover {
        opacity: 0.7;
      }
      &:focus {
        opacity: 0.8;
      }
      i {
        text-shadow: 1px 1px 1px $detalle6;
        font-size: 1.2rem;
        &:hover {
          opacity: 0.7;
        }
        &:focus {
          opacity: 0.7;
        }
      }
    }
  }
}

.scrollInicial {
  background-color: white;
  @include desktop {
    background-color: rgba(255, 255, 255, 0);
  }
  a {
    color: $fondo;
    text-shadow: 0px 0.5px #132f57;
    .textBarra {
      color: $fondo;
      text-shadow: 0px 0.5px #132f57;
    }
  }
}
.scrollFinal {
  background-color: white;
  @include desktop {
    background-color: $fondo;
  }
  a {
    color: $letra;
  }
}
.scrollNegro {
  background-color: black;
}
.active {
  opacity: 0.8;
  font-weight: bold !important;
  // border-bottom: 1px inset rgba(245, 245, 245, 0.7);
}
.navMenu {
  z-index: 10000;
  margin-top: 7rem;
  background-color: white;
  height: 2rem;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(190, 190, 190, 0.8);
  @include desktop {
    margin-top: 4rem;
  }
  a {
    text-decoration: none;
    color: $letra;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1.5rem;
  }
  .dropdown-toggle {
    margin-left: 1rem;
    margin-right: 1rem;
    cursor: pointer;
    color: $letra;
    font-size: 0.8rem;
  }
  .dropdown-item {
    margin: 0;
    font-size: 0.8rem;
    a {
      color: $fondo;
    }
  }
  .dropdown-menu {
    background-color: $detalle6;
    border: none;
    --bs-dropdown-color: $detalle6;
    --bs-dropdown-bg: $detalle6;
    --bs-dropdown-link-color: $detalle6;
    --bs-dropdown-link-hover-color: $detalle6;
    --bs-dropdown-link-hover-bg: rgba(175, 173, 173, 0.4);
    --bs-dropdown-link-active-color: $detalle6;
    --bs-dropdown-link-active-bg: $detalle;
    --bs-dropdown-link-disabled-color: $detalle6;
    --bs-dropdown-header-color: $detalle6;
  }
  .categoria {
    margin-left: 0;
  }
}
/* FOOTER  */
footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 2.6rem;
  display: flex;
  text-justify: center;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  font-size: 1rem;
  background-color: $azul;
  & a {
    width: 100%;
    color: $letra;
    text-justify: center;
    margin: auto;
    font-size: 1.2rem;
    text-decoration: none;
    &:hover {
      opacity: 0.5;
      color: $letra;
      font-size: 1.2rem;
    }
  }
}
