#contacto {
  background-image: url("../../../public/index/contacto.jpg");
  min-height: calc(100vh - 4rem);
  background-attachment: scroll;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  @include desktop {
    height: calc(100vh - 4rem);
    background-position: 0 0;
  }
  .cajaH2 {
    background-color: rgba(11, 24, 41, 0.8);
    height: 2rem;
    h2 {
      margin-bottom: 0;
      padding-top: 1rem;
    }
  }
  .transparencia {
    padding-top: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(11, 24, 41, 0.8);
    z-index: 8000;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include desktop {
      padding-top: 0;
      height: calc(100vh - 6rem);
    }
  }
  .orden {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    gap: 1.5rem;
  }
  & .formulario {
    display: flex;
    flex-direction: column;
    margin: 2rem;
    // width: 25rem;
  }
  .contactera {
    @include desktop {
      padding: 5rem;
    }
  }

  a {
    // margin: 2rem;

    color: $letra;
    padding-left: 0.5rem;
    font-weight: 400;
    font-size: 1.5rem;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
    }
  }

  & input {
    font-size: 1.2rem;
    color: $fondo;
    font-weight: bold;
    padding-left: 4%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 1rem;
    outline: none;
    background-color: $letra;
    border: 0;
    margin-bottom: 1rem;
    box-shadow: 0px 0px 5px 0px rgba(250, 247, 247, 0.75);
  }
  & label {
    font-size: 1.5rem;
    color: $letra;
    padding-left: 4%;
  }
  & textarea {
    font-size: 1.2rem;
    color: $fondo;
    padding-left: 4%;
    padding-top: 2%;
    border-radius: 0.5rem;
    outline: none;
    background-color: $letra;
    border: 0;
    font-weight: bold;
    box-shadow: 0px 0px 5px 0px rgba(250, 247, 247, 0.75);
  }
  ::placeholder {
    color: rgba(128, 128, 128, 0.5);
  }
  & .botonForm {
    margin: 1rem;
    width: 10rem;
    height: 2rem;
    // border: 1px solid $fondo;
    opacity: 1;
    background-color: $letra;
    color: $fondo;
    transition: all 1s;
    border-radius: 2rem;
    align-self: center;
    padding: 0;
    font-size: 1.2rem;
    font-weight: bold;
    box-shadow: 0px 0px 5px 0px rgba(250, 247, 247, 0.75);
    &:hover {
      opacity: 0.7;
      font-size: 1.2rem;
    }
  }
}
.custom-icon-class {
  border: none !important;
}
.btnSweet {
  background-color: $detalle !important;
  border: none !important;
}
input[type="checkbox"] {
  box-shadow: none !important;
}
.custom-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 5px;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #f1f1f1;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: $detalle6;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.labelCheck {
  font-size: 0.7rem;
}
