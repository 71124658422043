.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  i {
    font-size: 0.8rem !important;
    font-weight: 200 !important;
    color: $letra !important;
  }
  @include desktop {
    align-items: start;
  }
  .boton {
    border: none;
    width: fit-content;
  }
  input {
    margin: 1rem;
    border-radius: 1rem;
    padding-left: 1rem;
    width: 100%;
    border: solid 1px rgba(128, 128, 128, 0.5);
    &:focus {
      border: 2px solid $detalle6;
      outline: none;
    }
  }
}
