#admin {
  * {
    background-color: white !important;
    color: black !important;
    font-family: "Courier New", Courier, monospace;
  }
}
.create {
  * {
    // background-color: white!important;
    color: black !important;
  }
  .btnCreate {
    background-color: $detalle8;
    color: $letra !important;
  }
}
